import { SET_SAVED_SEARCHES } from 'store/actions/savedSearches'

const initialSaveSearchState = {
  funder: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  gift: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
}

const savedSearchesReducer = (state = initialSaveSearchState, { payload, type }) => {
  switch (type) {
    case SET_SAVED_SEARCHES:
      const { results } = payload
      return { ...state, ...results }
    default:
      return state
  }
}

export default savedSearchesReducer
