import React, { useState } from 'react'
import { Dialog, IconButton } from '@mui/material'
import { Close, Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { trackGiftSearchButtonClick } from 'utils/mixpanel'

const CharityCell = ({ cell }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const [showModal, setShowModal] = useState(false)

  const charity = cell.getValue()

  const handleClose = () => {
    setShowModal(false)
  }

  const handleOpen = async e => {
    setShowModal(true)
    await trackGiftSearchButtonClick({ button: 'table_popup.recipient' })
  }

  const formattedRecipientSize = new Intl.NumberFormat(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(charity.recipientSize)

  return (
    <div>
      <div className="ge-table-cell">
        <span className="tw-mr-1">{charity.name}</span>
        <IconButton className="ge-icon-button" onClick={handleOpen}>
          <Search />
        </IconButton>
      </div>
      <Dialog open={showModal} onClose={handleClose} className="Explorer ge-charity-card">
        <div className="ge-dialog-close">
          <Close onClick={handleClose} />
        </div>
        <div className="tw-text-black">
          <h1 className="tw-text-lg tw-mt-1">{t.explorer.recipient}</h1>
          <h2 className="tw-text-xl tw-mt-1">{charity.name}</h2>
          {charity.businessNumber && (
            <p className="tw-text-md">
              <span className="tw-text-md tw-font-semibold">{t.explorer.businessNumber}: </span>
              {charity.businessNumber}
            </p>
          )}
          <p className="tw-text-md">
            <span className="tw-text-md tw-font-semibold">{t.explorer.focus}: </span>
            {cell.row.original.focus?.name || t.explorer.focusUnknown}
          </p>
          {cell.row.original.location.name && (
            <p className="tw-text-md">
              <span className="tw-text-md tw-font-semibold">{t.explorer.location}: </span>
              {cell.row.original.location.name}
            </p>
          )}
          {charity.recipientSize && (
            <p className="tw-text-md">
              <span className="tw-text-md tw-font-semibold">{t.explorer.recipient_size}: </span>
              {formattedRecipientSize}
            </p>
          )}
        </div>
      </Dialog>
    </div>
  )
}

export default CharityCell
