import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// The visible flag is used for an optimization where only top level children of collapsed lists
// are rendered. This results in a faster initial render time for the component as a whole without
// sacrificing the smooth expanding/collapsing animation.

const FocusListItem = ({ facet, visible, selected, toggle }) => {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!expanded)
  }

  const isSelected = selected.includes(facet.id)
  const hasChildren = facet.children.length > 0

  const childSelected =
    !isSelected &&
    hasChildren &&
    facet.children
      .reduce((a, child) => [...a, child, ...child.children], [])
      .some(child => selected.includes(child.id))

  return (
    <>
      <ListItem disablePadding>
        <ListItemIcon>
          <Checkbox
            className={`ge-focus-checkbox ${isSelected ? 'active' : ''}`}
            checked={isSelected}
            indeterminate={childSelected}
            onChange={() => toggle(facet.id)}
          />
        </ListItemIcon>
        <ListItemText primary={facet.name} />
        {hasChildren && (
          <ListItemIcon onClick={handleClick} className="ge-focus-expand">
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        )}
      </ListItem>
      {visible && hasChildren && (
        <Collapse in={expanded} timeout="auto" className="ge-focus-sublist">
          {facet.children.map(child => (
            <FocusListItem
              key={child.id}
              facet={child}
              visible={expanded}
              selected={selected}
              toggle={toggle}
            />
          ))}
        </Collapse>
      )}
    </>
  )
}

const FocusListSection = ({ title, facets, selected, toggle }) => {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        <ListItemIcon className="ge-focus-expand">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
      </ListItemButton>
      <Collapse in={expanded} timeout="auto">
        <List>
          {facets.map(facet => (
            <FocusListItem
              key={facet.id}
              facet={facet}
              visible={expanded}
              selected={selected}
              toggle={toggle}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

const FocusList = ({ facets, selected, setSelected }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const toggleFacet = (facetId, facetType) => {
    if (selected[facetType].includes(facetId)) {
      const newSelected = [...selected[facetType]]
      newSelected.splice(newSelected.indexOf(facetId), 1)
      setSelected({
        ...selected,
        [facetType]: [...newSelected]
      })
    } else {
      setSelected({
        ...selected,
        [facetType]: [...selected[facetType], facetId]
      })
    }
  }

  return (
    <List className="ge-focus-container">
      {Object.entries(facets).map(([facetType, facetGroup]) => (
        <FocusListSection
          key={facetType}
          title={t.explorer.focusTypes[facetType]}
          facets={facetGroup}
          selected={selected[facetType]}
          toggle={facetId => toggleFacet(facetId, facetType)}
        />
      ))}
    </List>
  )
}

export default FocusList
