import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { selectGiftSearchStats } from 'store/selectors/giftSearch'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ ...props }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const stats = useSelector(selectGiftSearchStats)

  const labels = stats.giftAmountByFunder?.map(funder => funder.name) || []
  const series = stats.giftAmountByFunder?.map(funder => funder.total) || []

  const data = {
    labels: labels,
    datasets: [
      {
        label: t.explorer.total_amount_given,
        data: series,
        borderWidth: 1,
        backgroundColor: '#ffc72c'
      }
    ]
  }

  const options = {
    aspectRatio: 1,
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        ticks: {
          autoSkip: false,
          callback: (value, index) => {
            const label = labels[index]
            return label.length > 23 ? `${label.substring(0, 25)}…` : label
          }
        }
      }
    }
  }

  return stats.giftAmountTotalInYearRange > 0 ? (
    <Bar options={options} data={data} {...props} />
  ) : (
    <div className="ge-graph-no-data">{t.explorer.noDataToDisplay}</div>
  )
}

export default BarChart
