import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import FilterHeader from './FilterHeader'
import SimpleInputField from './SimpleInputField'

const PurposeHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['details']

  return (
    <FilterHeader
      column={column}
      isActive={isActive}
    >
      <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.search_description}
      </p>
      <SimpleInputField type="text" id="details" defaultValue={filters['details']} />
    </FilterHeader>
  )
}

export default PurposeHeader
