import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { useDebounce } from 'hooks'

import backend from 'utils/backend'
import {
  trackGiftSearchAutocompleteItemClick,
  trackGiftSearchButtonClick,
  trackGiftSearchKeywords
} from 'utils/mixpanel'

import {
  addGiftSearchCallback,
  setGiftSearchFilterByKey,
  setGiftSearchNameCacheEntry
} from 'store/actions/giftSearch'

import DefaultButton from './DefaultButton'

const SearchBar = () => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [localText, setLocalText] = useState('')
  const debouncedLocalText = useDebounce(localText, 500)
  const [autocompleteResults, setAutocompleteResults] = useState([])
  const [autocompleteValue, setAutocompleteValue] = useState(null)

  useEffect(async () => {
    if (debouncedLocalText.length >= 3) {
      try {
        const {
          data: { results }
        } = await backend.get(`api/search/giftsautocomplete/?search=${localText}`)
        setAutocompleteResults(results)
      } catch (error) {
        setAutocompleteResults([])
      }
    }
  }, [debouncedLocalText])

  const applyKeywordFilter = keyword => {
    dispatch(
      addGiftSearchCallback(async count => {
        await trackGiftSearchKeywords({ searchText: keyword, count })
      })
    )
    dispatch(setGiftSearchFilterByKey({ keyword }))
  }

  const handleSelect = async e => {
    applyKeywordFilter(localText)

    setLocalText('')
    setAutocompleteResults([])
    setAutocompleteValue(null)

    await trackGiftSearchButtonClick({ button: 'search' })
  }

  const handleAutocompleteSelect = async (e, value) => {
    if (value.type === 'keyword') {
      applyKeywordFilter(value.name)
    } else if (value.type === 'charity') {
      dispatch(setGiftSearchFilterByKey({ charity: value.id }))
    } else if (value.type === 'funder') {
      dispatch(setGiftSearchFilterByKey({ funder: value.id }))
    } else if (value.type === 'cause') {
      dispatch(setGiftSearchFilterByKey({ causes: [value.id] }))
    } else if (value.type === 'population') {
      dispatch(setGiftSearchFilterByKey({ populations: [value.id] }))
    } else if (value.type === 'international') {
      dispatch(setGiftSearchFilterByKey({ internationals: [value.id] }))
    }

    setLocalText('')
    setAutocompleteResults([])
    setAutocompleteValue(null)

    if (value.type !== 'keyword') {
      dispatch(setGiftSearchNameCacheEntry(value.type, value.id, value.name))
    }

    await trackGiftSearchAutocompleteItemClick({
      itemType: value.type,
      value: value.id || value.name
    })
  }

  const handleSearchChange = (e, value, reason) => {
    if (reason === 'reset') {
      return
    }

    // The value param appears to be broken when pressing the enter key,
    // so use the event target's value instead.
    const query = e.target.value || ''
    setLocalText(query)

    if (query.length < 3) {
      setAutocompleteResults([])
    }
  }

  const options = [
    ...(localText.length > 0 ? [{ type: 'keyword', name: localText }] : []),
    ...autocompleteResults
  ]

  return (
    <div className="relative">
      <div className={`ge-search-field-wrapper tw-flex tw-gap-2 tw-items-stretch`}>
        <Autocomplete
          id="gifts-search-bar"
          fullWidth
          style={{ maxWidth: '520px' }}
          freeSolo
          size="small"
          color="secondary"
          filterOptions={x => x}
          options={options}
          renderOption={(props, option) => {
            const { key, id, ...optionProps } = props
            return (
              <li
                key={id}
                {...optionProps}
                className="tw-flex tw-gap-1 tw-p-2 tw-pl-3 hover:tw-bg-lighterGrey"
              >
                <span className="tw-text-darkGrey">
                  {t.explorer.autocompleteTypes[option.type]}:
                </span>
                <span>{option.name}</span>
              </li>
            )
          }}
          autoHighlight
          onChange={handleAutocompleteSelect}
          value={autocompleteValue}
          getOptionLabel={option => option.name}
          autoComplete
          filterSelectedOptions
          noOptionsText={t.explorer.no_results}
          onInputChange={handleSearchChange}
          inputValue={localText}
          renderInput={params => (
            <TextField {...params} placeholder={t.explorer.keywordSearchPlaceholder} />
          )}
        />
        <DefaultButton
          className="ge-search-button"
          onClick={handleSelect}
          label={t.explorer.search}
        />
      </div>
    </div>
  )
}

export default SearchBar
