import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from '@mui/material'
import { Close, Star } from '@mui/icons-material'

import { trackGiftSearchButtonClick } from 'utils/mixpanel'

import { addSavedSearch, getSavedSearches } from 'store/actions/savedSearches'
import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import SimpleInputField from './SimpleInputField'
import ButtonWithIcon from './ButtonWithIcon'
import ResultsSummary from './ResultsSummary'
import DefaultButton from './DefaultButton'

const SaveSearch = ({ savedSearches }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const filters = useSelector(selectGiftSearchFilters)

  const [showForm, setShowForm] = useState(false)
  const [error, setError] = useState()
  const [value, setValue] = useState('')

  const handleClose = () => {
    setShowForm(false)
  }

  const handleOpen = async e => {
    setValue('')
    setShowForm(true)
    await trackGiftSearchButtonClick({ button: 'saved_search.save' })
  }

  const handleChange = e => {
    const duplicateName = savedSearches.results.find(s => s.name === e.target.value)
    if (duplicateName) {
      setError(t.explorer.duplicate_title_error)
    } else {
      setError(null)
    }

    setValue(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const name = formData.get('name')

    const duplicateName = savedSearches.results.find(s => s.name === name)
    if (!duplicateName) {
      await dispatch(addSavedSearch({ type: 'gift', name, search_url: window.location.href }))
      dispatch(getSavedSearches({ type: 'gift' }))
      setError(null)
      setValue('')
      handleClose()
    }
  }

  const filterKeys = Object.keys(filters)
  const activeFilterKeys = filterKeys.filter(k => !!filters[k])
  const today = new Date()
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
  const localizedDateString = today.toLocaleTimeString([], options)
  const defaultSearchName = `${t.explorer.search_saved_on} ${localizedDateString}`

  return (
    <>
      <ButtonWithIcon
        onClick={handleOpen}
        color="grey"
        label={t.explorer.save_my_search}
        Icon={Star}
      />
      <Dialog open={showForm} onClose={handleClose} className="Explorer">
        <div className="ge-dialog-close">
          <Close onClick={handleClose} />
        </div>
        {activeFilterKeys.length === 0 ? (
          <p>{t.explorer.no_filters}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="tw-mb-5">
              <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
                {t.explorer.save_my_search}
              </p>
              <ResultsSummary filters={filters} displayMode={true} />
              <div>
                <SimpleInputField
                  id="name"
                  label={t.explorer.save_my_search_label}
                  type="text"
                  maxLength={50}
                  placeholder={defaultSearchName}
                  errorMessage={error}
                  onChange={handleChange}
                  value={value}
                />
              </div>
            </div>
            <div className="tw-flex tw-justify-end">
              <DefaultButton type="submit" label={t.explorer.save} />
            </div>
          </form>
        )}
      </Dialog>
    </>
  )
}

export default SaveSearch
