import { Button } from '@mui/material'

const DefaultButton = ({ className = '', label, ...props }) => {
  return (
    <Button className={`button-primary ${className}`} {...props}>
      <span>{label}</span>
    </Button>
  )
}

export default DefaultButton
