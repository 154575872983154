import _ from 'lodash'

import {
  GIFT_SEARCH_SET_URL_SYNCED,
  GIFT_SEARCH_SET_LOADING_STATE,
  GIFT_SEARCH_UPDATE_RESULTS,
  GIFT_SEARCH_UPDATE_STATS,
  GIFT_SEARCH_ADD_CALLBACK,
  GIFT_SEARCH_CLEAR_CALLBACKS,
  GIFT_SEARCH_SET_PAGINATION,
  GIFT_SEARCH_SET_SORTING,
  GIFT_SEARCH_SET_FILTERS,
  GIFT_SEARCH_SET_FILTER_BY_KEY,
  GIFT_SEARCH_UPDATE_NAME_CACHE,
  GIFT_SEARCH_RESET,
  GIFT_SEARCH_FAILURE
} from 'store/actions/giftSearch'

const initialState = {
  urlSynced: false,
  loading: true,
  pagination: {
    pageIndex: 0,
    pageSize: 10
  },
  sorting: [],
  filters: {},
  results: {
    count: 0,
    records: []
  },
  stats: {},
  callbacks: [],
  nameCache: {},
  error: null
}

const giftSearchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GIFT_SEARCH_SET_URL_SYNCED:
      return {
        ...state,
        urlSynced: true
      }
    case GIFT_SEARCH_SET_LOADING_STATE:
      const { loading } = payload
      return {
        ...state,
        loading
      }
    case GIFT_SEARCH_UPDATE_RESULTS:
      const { count, records } = payload
      return {
        ...state,
        results: {
          count,
          records
        }
      }
    case GIFT_SEARCH_UPDATE_STATS:
      const { stats } = payload
      return {
        ...state,
        stats
      }
    case GIFT_SEARCH_ADD_CALLBACK:
      const { callback } = payload
      return {
        ...state,
        callbacks: [...state.callbacks, callback]
      }
    case GIFT_SEARCH_CLEAR_CALLBACKS:
      return {
        ...state,
        callbacks: []
      }
    case GIFT_SEARCH_SET_PAGINATION:
      const { pagination } = payload
      return {
        ...state,
        pagination: {
          ...pagination
        }
      }
    case GIFT_SEARCH_SET_SORTING:
      const { sorting } = payload
      return {
        ...state,
        sorting
      }
    case GIFT_SEARCH_SET_FILTERS:
      const { filters } = payload
      return {
        ...state,
        filters
      }
    case GIFT_SEARCH_SET_FILTER_BY_KEY:
      const { filtersByKey } = payload
      const newFilters = {
        ...state.filters,
        ...filtersByKey
      }
      const filtersWithValue = _.omit(
        newFilters,
        Object.keys(newFilters).filter(filterKey => !newFilters[filterKey])
      )
      return {
        ...state,
        filters: {
          ...filtersWithValue
        }
      }
    case GIFT_SEARCH_UPDATE_NAME_CACHE:
      return {
        ...state,
        nameCache: {
          ...state.nameCache,
          [payload.field]: {
            ...state.nameCache[payload.field],
            [payload.id]: payload.name
          }
        }
      }
    case GIFT_SEARCH_RESET:
      return {
        ...initialState,
        urlSynced: state.urlSynced
      }
    case GIFT_SEARCH_FAILURE:
      const { error } = payload
      return {
        ...state,
        error
      }
    default:
      return state
  }
}

export default giftSearchReducer
