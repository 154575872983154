import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import FilterHeader from './FilterHeader'
import SimpleInputField from './SimpleInputField'

const AmountHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['giftAmountMin'] || !!filters['giftAmountMax']

  return (
    <FilterHeader column={column} isActive={isActive}>
      <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.filter_by_gift_size}
      </p>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <div>
          <SimpleInputField
            id="giftAmountMin"
            label={t.explorer.min_size}
            type="number"
            defaultValue={filters['giftAmountMin']}
          />
        </div>
        <p className="tw-mt-6">{t.explorer.rangeTo}</p>
        <div>
          <SimpleInputField
            id="giftAmountMax"
            label={t.explorer.max_size}
            type="number"
            defaultValue={filters['giftAmountMax']}
          />
        </div>
      </div>
    </FilterHeader>
  )
}

export default AmountHeader
