import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { selectGiftSearchStats } from 'store/selectors/giftSearch'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ ...props }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const stats = useSelector(selectGiftSearchStats)

  const giftAmountAcrossTopRecipients =
    stats.giftAmountByRecipient?.reduce((total, charity) => total + charity.total, 0) || 0
  const giftAmountAcrossOtherRecipients =
    stats.giftAmountTotalInYearRange - giftAmountAcrossTopRecipients

  const labels = stats.giftAmountByRecipient?.map(charity => charity.name) || []
  if (labels.length > 0) {
    labels.push('Other')
  }

  const series = stats.giftAmountByRecipient?.map(charity => charity.total) || []
  if (series.length > 0) {
    series.push(giftAmountAcrossOtherRecipients)
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: t.explorer.total_amount_received,
        data: series,
        borderWidth: 1,
        backgroundColor: ['#ffc72c', '#ffe085', '#f5b400']
      }
    ]
  }

  const options = {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return stats.giftAmountTotalInYearRange > 0 ? (
    <Pie options={options} data={data} {...props} />
  ) : (
    <div className="ge-graph-no-data">{t.explorer.noDataToDisplay}</div>
  )
}

export default PieChart
