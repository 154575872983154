import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RoleEnum } from 'store/reducers/user'
import { selectGiftSearchFilters } from 'store/selectors/giftSearch'
import { selectRole } from 'store/selectors/user'

import FilterHeader from './FilterHeader'
import SimpleSelectField from './SimpleSelectField'

// TODO: Query gift data year range from the backend
const MAX_YEAR = 2024
const MIN_YEAR = 1980

const YearHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const role = useSelector(selectRole)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['yearMin'] || !!filters['yearMax']

  const [yearMin, setYearMin] = useState('')
  const [yearMax, setYearMax] = useState('')

  const maxYearValue = MAX_YEAR
  const minYearValue = role === RoleEnum.PREMIUM ? MIN_YEAR : maxYearValue - 4

  const years = _.range(maxYearValue, minYearValue - 1, -1).map(y => ({ value: y, label: y }))
  const options = [{ value: '', label: '--' }, ...years]

  const onOpen = () => {
    setYearMin(filters['yearMin'] || '')
    setYearMax(filters['yearMax'] || '')
  }

  const handleYearMinChange = e => {
    const value = e.target.value
    setYearMin(value)
    if (yearMax !== '' && value !== '' && yearMax < value) {
      setYearMax(value)
    }
  }

  const handleYearMaxChange = e => {
    const value = e.target.value
    setYearMax(value)
    if (yearMin !== '' && value !== '' && yearMin > value) {
      setYearMin(value)
    }
  }

  const yearsAvailableLabel = t.explorer.years_available
    .replace('{start}', minYearValue)
    .replace('{end}', maxYearValue)

  return (
    <FilterHeader column={column} isActive={isActive} onOpen={onOpen}>
      <p className="tw-w-full tw-block tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.filter_by_year}
      </p>
      <p className="tw-w-full tw-block tw-mb-4">{yearsAvailableLabel}</p>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <div>
          <SimpleSelectField
            id="yearMin"
            label={t.explorer.start_year}
            options={options}
            value={yearMin}
            onChange={handleYearMinChange}
          />
        </div>
        <p className="tw-mt-6">{t.explorer.rangeTo}</p>
        <div>
          <SimpleSelectField
            id="yearMax"
            label={t.explorer.end_year}
            options={options}
            value={yearMax}
            onChange={handleYearMaxChange}
          />
        </div>
      </div>
    </FilterHeader>
  )
}

export default YearHeader
