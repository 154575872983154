import { Close, FilterList } from '@mui/icons-material'
import { Button, Dialog } from '@mui/material'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { trackGiftSearchButtonClick, trackGiftSearchFilter } from 'utils/mixpanel'

import { setGiftSearchFilterByKey } from 'store/actions/giftSearch'

import DefaultButton from './DefaultButton'

const FilterHeader = ({ column, isActive, onOpen, applyFilters, children }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)

  const handleOpen = async e => {
    e.stopPropagation()
    if (onOpen) {
      onOpen()
    }
    setShowForm(true)
    await trackGiftSearchButtonClick({ button: `filter_header.${column.columnDef.accessorKey}` })
  }

  const handleClose = e => {
    e.stopPropagation()
    setShowForm(false)
  }

  const defaultApplyFilters = data => async dispatch => {
    dispatch(setGiftSearchFilterByKey(data))
    for (const [filter, value] of Object.entries(data)) {
      if (!!value) {
        await trackGiftSearchFilter({ filter, value })
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())
    dispatch(applyFilters ? applyFilters(data) : defaultApplyFilters(data))
    setShowForm(false)
  }

  const buttonClassName = `ge-header-button ge-header-button-${column.columnDef.accessorKey} ${
    isActive ? 'active' : ''
  }`

  return (
    <div>
      <Button onClick={handleOpen} className={buttonClassName}>
        <div className="tw-inline-flex tw-items-center tw-text-dark">
          <FilterList />
          <span className="tw-ml-1">{column.columnDef.header}</span>
        </div>
      </Button>
      <Dialog
        open={showForm}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        className="Explorer"
      >
        <div className="ge-dialog-close">
          <Close onClick={handleClose} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="tw-mb-5">{children}</div>
          <div className="tw-pt-3 tw-flex tw-justify-end tw-flex-none tw-border tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-grey">
            <DefaultButton type="submit" label={t.explorer.apply} />
          </div>
        </form>
      </Dialog>
    </div>
  )
}

export default FilterHeader
