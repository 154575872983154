import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectIsLibraryMode } from 'store/selectors/user'
import { redirectTo } from 'store/actions/user'
import { useHotjar } from 'utils/hotjar'
import { trackPageView } from 'utils/mixpanel'

const ContactExplorer = () => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const dispatch = useDispatch()
  const isLibraryMode = useSelector(selectIsLibraryMode)

  useEffect(() => {
    if (isLibraryMode) {
      dispatch(redirectTo('/search'))
    }
  }, [isLibraryMode])

  useHotjar()

  useEffect(trackPageView, [])

  return (
    <div className="Contact">
      <iframe className="frame" src={t.contactExplorer.url} allowFullScreen />
      <div className="footer">
        <a href={t.contactExplorer.privacy_url}>{t.contactExplorer.privacy}</a>
        <span> &nbsp; | &nbsp; </span>
        <span>Google Data Studio</span>
      </div>
    </div>
  )
}

export default ContactExplorer
