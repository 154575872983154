import { useTranslation } from 'react-i18next'
import {  useSelector } from 'react-redux'

import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import AutocompleteField from './AutocompleteField'
import FilterHeader from './FilterHeader'

const RegionHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['location'] || !!filters['locationName']

  return (
    <FilterHeader
      column={column}
      isActive={isActive}
    >
      <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.search_locations}
      </p>
      <AutocompleteField
        field="location"
        defaultValue={filters['locationName']}
        defaultSelectedValue={filters['location']}
        inputProps={{ placeholder: t.explorer.locationNamePlaceholder }}
      />
    </FilterHeader>
  )
}

export default RegionHeader
