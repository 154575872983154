import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import AutocompleteField from './AutocompleteField'
import FilterHeader from './FilterHeader'

const FunderHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['funder'] || !!filters['funderName']

  return (
    <FilterHeader
      column={column}
      isActive={isActive}
    >
      <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.search_by_name}
      </p>
      <AutocompleteField
        field="funder"
        defaultValue={filters['funderName']}
        defaultSelectedValue={filters['funder']}
        inputProps={{ placeholder: t.explorer.funderNamePlaceholder }}
      />
    </FilterHeader>
  )
}

export default FunderHeader
