import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Dialog, IconButton } from '@mui/material'
import { Close, Delete, Star } from '@mui/icons-material'

import { trackGiftSearchButtonClick } from 'utils/mixpanel'

import { resetGiftSearch, syncGiftSearchURL, updateGiftSearchURL } from 'store/actions/giftSearch'
import { deleteSavedSearch, getSavedSearches } from 'store/actions/savedSearches'

import DefaultButton from './DefaultButton'
import ButtonWithIcon from './ButtonWithIcon'

function SavedSearchesDropdown({ savedSearches }) {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)

  const handleClose = () => {
    setShowForm(false)
  }

  const handleOpen = async e => {
    setShowForm(true)
    await trackGiftSearchButtonClick({ button: 'saved_search.list' })
  }

  const handleApply = search => {
    if (search.searchUrl) {
      const savedUrl = new URL(search.searchUrl)
      dispatch(resetGiftSearch)
      dispatch(syncGiftSearchURL(savedUrl.search))
      dispatch(updateGiftSearchURL)
    }
    handleClose()
  }

  const handleDelete = async search => {
    await dispatch(deleteSavedSearch(search.id))
    dispatch(getSavedSearches({ type: 'gift' }))
  }

  return (
    <div className={`ge-search-field-wrapper tw-flex tw-gap-2 tw-mt-2`}>
      <ButtonWithIcon
        onClick={handleOpen}
        color="grey"
        label={t.explorer.your_saved_searches}
        Icon={Star}
      />
      <Dialog open={showForm} onClose={handleClose} className="Explorer ge-saved-searches">
        <div className="ge-dialog-close">
          <Close onClick={handleClose} />
        </div>
        {savedSearches.count === 0 ? (
          <p>{t.explorer.no_saved_searches}</p>
        ) : (
          <div>
            <div className="tw-mb-2">
              <p className="tw-w-full tw-block tw-mb-4 tw-text-md tw-text-black tw-font-semibold">
                {t.explorer.use_saved_search}
              </p>
              {savedSearches.results.map(search => {
                return (
                  <div
                    key={search.id}
                    className="tw-flex tw-justify-between tw-items-center tw-gap-3"
                  >
                    <p className="tw-text-md tw-m-1">{search.name}</p>
                    <div className="tw-flex tw-flew-row">
                      <DefaultButton onClick={() => handleApply(search)} label={t.explorer.apply} />
                      <IconButton onClick={() => handleDelete(search)} className="ge-delete">
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default SavedSearchesDropdown
