import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { trackGiftSearchFilter } from 'utils/mixpanel'

import { setGiftSearchFilterByKey } from 'store/actions/giftSearch'

import { selectFocusFacets } from 'store/selectors/facets'
import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import FilterHeader from './FilterHeader'
import FocusList from './FocusList'
import Loader from './Loader'

const FocusHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['causes'] || !!filters['populations'] || !!filters['internationals']

  const facets = useSelector(selectFocusFacets)
  const facetsLoaded = Object.values(facets).every(group => !!group && group.length > 0)

  const [selected, setSelected] = useState(
    Object.fromEntries(Object.keys(facets).map(type => [type, []]))
  )

  const applyFilters = data => async dispatch => {
    const entries = Object.entries(selected)
    dispatch(
      setGiftSearchFilterByKey({
        ...Object.fromEntries(
          entries.map(([type, group]) => [type, group.length > 0 ? group : null])
        )
      })
    )

    for (const [type, group] of entries) {
      if (group.length > 0) {
        await trackGiftSearchFilter({ filter: type, value: group })
      }
    }
  }

  const updateSelected = () => {
    const newSelected = {}
    for (const key of Object.keys(facets)) {
      newSelected[key] = filters[key] || []
    }
    setSelected(newSelected)
  }

  return (
    <FilterHeader
      column={column}
      isActive={isActive}
      onOpen={updateSelected}
      applyFilters={applyFilters}
    >
      <div className="tw-flex tw-flex-col tw-w-full">
        <p className="tw-w-full tw-flex-none tw-tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
          {t.explorer.filter_by_focus}
        </p>
        {facetsLoaded ? (
          <FocusList facets={facets} selected={selected} setSelected={setSelected} />
        ) : (
          <Loader />
        )}
      </div>
    </FilterHeader>
  )
}

export default FocusHeader
